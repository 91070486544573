import {Layout} from '../../components/layout';
import * as styles from './index.module.css';

const AnalyticsWEPage = () => (
    <Layout title="Analytics (WE)" scope="analytics.we">
        <div className={styles.wrapper}>
            <iframe
                title="WE YT Quarterly Performace Dashboard_v4"
                width="100%"
                height="100%"
                src="https://app.powerbi.com/reportEmbed?reportId=f049123a-2949-49ca-af76-7cc7ec56cb2d&autoAuth=true&ctid=ae087f2d-8a82-46dd-a538-38bebd294479"
                className={styles.embed}
            >
            </iframe>
        </div>
    </Layout>
);

export default AnalyticsWEPage;
